import React from "react";

const GridItem = ({ gValue, gLabel, gIcon }) => {
	return (
		<>
			<div className="card-item">
				<div className="grid-item-value">{gValue}</div>
				<div className="grid-item-label">{gLabel}</div>
			</div>
			<div className="card-icon">{gIcon && gIcon}</div>
		</>
	);
};
export default GridItem;
