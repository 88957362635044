import { useEffect } from 'react';
import { Table, TableHeading, TableCell } from "@nike/eds";


const SimpleTable = (props) => {

    return (
        <>
            <div className="eds-grid eds-grid--l-cols-1 eds-grid-1-m-cols-1">
                <h3 className="grid-component-title mb-16">{props.title}</h3>
            </div>
            <div className={props.classes ? `${props.classes} com-table` : 'com-table'}>
                <Table className='w-100 eds-table first-column-header'>
                    <thead>
                        <tr>
                            {(props.tableData && props.tableData.header) && props.tableData.header.map((head) => <TableHeading className="eds-type--legal-1" key={head}>{head}</TableHeading>)}
                        </tr>
                    </thead>
                    <tbody>
                        {(props.tableData && props.tableData.body) && props.tableData.body.map((body) => {
                            return (
                                <tr key={body[0]}>
                                    {body[0] && <TableCell className="eds-type--legal-1">{body[0]}</TableCell>}
                                    {body[1] && <TableCell className="eds-type--legal-1">{body[1]}</TableCell>}
                                    {body[2] && <TableCell className="eds-type--legal-1">{body[2]}</TableCell>}
                                    {body[3] && <TableCell className="eds-type--legal-1">{body[3]}</TableCell>}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default SimpleTable;