import React, { useEffect, useState, useRef } from "react";
// import { useHistory } from "react-router-dom";
import "@nike/eds/dist/index.css";
import "../dashboard/css/index.css";
import { Card, Tab, TabGroup } from "@nike/eds";
import OrderByTypeGraph from "../dashboard/components/orderByTypeGraph/orderByTypeGraph";
import GridItem from "../dashboard/components/gridItem/gridItem";
import SimpleEchart from "../dashboard/components/simpleEchart/simpleEchart";
import SimpleTable from "../dashboard/components/simpleTable/simpleTable";
import {
	// getComDashboardOrderDetails,
	getRenderedPercentage,
	getDailyTnrendOverview,
	getResourceUtilization,
	getDailyInfraUtilization,
	getRegionsCount,
	getOrderByType,
	getOrderByIntakeChannel,
	fetchDateParams,
} from "../../../observer/dailyTrend";
import HourlyTrend from "./components/hourlyTrend";
import MemoryUtilization from "./images/memoryutilization.png";
import { useDispatch, useSelector } from "react-redux";
import PieChartWithLabel from "./components/pieChartWithLabel";
import InfraUtilization from "./components/infraUtilization";
import LineChart from "./components/lineChart";
import TopComponent from "./components/topComponent/TopComponent";
import CostAndMemoryUtilization from "./components/costAndMemoryUtilization/CostAndMemoryUtilization";
import { setFilterDate } from "../../../_reducer/dashboardSlice";

const DailyTrendDashboard = () => {
	const today = new Date();
	today.setDate(today.getDate() - 1);

	const [mount, setMount] = useState(true);
	const [overview, setOverview] = useState({});
	const [prodInfraUse, setProdInfraUse] = useState({});
	const [regionsCount, setRegionsCount] = useState([]);
	const [orderByType, setOrderByType] = useState([]);
	const [orderByIntakeChannel, setOrderByIntakeChannel] = useState([]);
	const [date, setDate] = useState(today);
	// const filterDate = useSelector((state) => {
	// 	const today = new Date();
	// 	today.setDate(today.getDate() - 1);
	// 	return today;
	// 	// return state.dashboardSliceReducer.dashboardDate ? new Date(state.dashboardSliceReducer.dashboardDate) : today;
	// });

	const dispatch = useDispatch();

	const costRef = useRef(null);
	const fromDateString = `${String(date.getMonth() + 1).padStart(
		2,
		"0"
	)}-${String(date.getDate()).padStart(2, "0")}-${date.getFullYear()} 00:00:00`;
	const toDateString = `${String(date.getMonth() + 1).padStart(
		2,
		"0"
	)}-${String(date.getDate()).padStart(2, "0")}-${date.getFullYear()} 23:59:59`;

	const fetchData = (date) => {
		const dates = fetchDateParams(date);
		getOrderByIntakeChannel(dates).then((data) =>
			setOrderByIntakeChannel(data)
		);
		// getComDashboardOrderDetails().then((data) => setOrderDetails(data));
		getOrderByType(dates).then((data) => setOrderByType(data));
		getDailyInfraUtilization(date).then((data) => setProdInfraUse(data));
		getDailyTnrendOverview(dates).then((data) => {
			setOverview(data);
		});
		// getPercentageDeviation().then((data) => setPercentageDeviation(data));
		// getResourceUtilization(dates).then((data) => setResourceUtilization(data));
		// getOpenCasesByOrderTypes(dates).then((data) => setOpenCasesByOrderTypes(data));
		getRegionsCount(dates).then((data) => {
			setRegionsCount(data);
		});

		setDate(date);
	};

	useEffect(() => {
		setTimeout(() => {
			document.querySelector(
				".eds-app-header h1.eds-app-header__name"
			).innerHTML = "OSD Daily Trend And Metrics";
			if (mount) {
				dispatch(setFilterDate(date));
				// setFilterDate(today);
				fetchData(date);
				setMount(false);
			}
		}, 100);
	}, [mount]);

	return (
		<div className="dashboard-container com-dashboard-container">
			<div>
				{/* <h2 className="text-center mb-16">
					OSD DAILY TRENDS AND METRICS REPORT
				</h2> */}
				<TopComponent fetchData={fetchData} />
				<div className="d-flex justify-content-space-between align-items-end mb-16">
					<div>
						<p className="m-0 eds-type--body-3">
							<b>SOURCE: </b> RDS, AWS
						</p>
						<p className="m-0 eds-type--body-3">
							<b>DOMAIN: </b> ORDER SERVICES DOMAIN
						</p>
					</div>
					<div>
						<p className="m-0 eds-type--body-3 text-right">
							<b>DATE TIME RANGE: </b> {fromDateString} - {toDateString} (PST)
						</p>
						<p className="m-0 eds-type--body-3">
							OSD BUSINESS DASHBOARD can be found at{" "}
							<a
								href="https://osd-business-dashboard.nike.com"
								target={"_blank"}
								style={{ color: "#0d6efd" }}
							>
								https://osd-business-dashboard.nike.com
							</a>
						</p>
					</div>
				</div>
			</div>
			<div className="eds-grid eds-grid--s-cols-1 eds-grid--m-cols-4 eds-grid--l-cols-8 mb-16">
				<Card className="eds-card eds-elevation--2">
					<GridItem
						gValue={
							<ul className="d-flex justify-content-space-between p-0 m-0">
								<li className="d-flex flex-direction-column justify-content-space-between">
									<h2 className="m-0">
										{overview.received && overview.received.count}
									</h2>
								</li>
								<li className="d-flex justify-content-space-between">
									{overview.received && (
										<p className="eds-type--legal-1">
											<span className="eds-type--legal-3">
												{getRenderedPercentage(overview.received.value)}
											</span>{" "}
											from yesterday
										</p>
									)}
								</li>
							</ul>
						}
						gLabel={"ORDERS RECEIVED BY OSD"}
					/>
				</Card>
				<Card className="eds-card eds-elevation--2">
					<GridItem
						gValue={
							<ul className="d-flex justify-content-space-between p-0 m-0">
								<li className="d-flex justify-content-space-between">
									<h2>{overview.failedInS4 && overview.failedInS4.count}</h2>
								</li>
							</ul>
						}
						gLabel={"ORDERS SENT TO S4"}
					/>
				</Card>
				<Card className="eds-card eds-elevation--2">
					<GridItem
						gValue={
							<ul className="d-flex justify-content-space-between p-0 m-0">
								<li className="d-flex justify-content-space-between">
									<h2>{overview.splitOrders && overview.splitOrders.count}</h2>
								</li>
							</ul>
						}
						gLabel={"SPLIT ORDERS"}
					/>
				</Card>
				<Card className="eds-card eds-elevation--2">
					<GridItem
						gValue={
							<ul className="d-flex justify-content-space-between p-0 m-0">
								<li className="d-flex justify-content-space-between">
									<h2>
										{overview.technicalIssues && overview.technicalIssues.count}
									</h2>
								</li>
							</ul>
						}
						gLabel={"ORDERS IN DLQ/IN-FLIGHT"}
					/>
				</Card>
				<Card className="eds-card eds-elevation--2">
					<GridItem
						gValue={
							<ul className="d-flex justify-content-space-between p-0 m-0">
								<li className="d-flex justify-content-space-between">
									<h2>
										{overview.businessCases && overview.businessCases.count}
									</h2>
								</li>
							</ul>
						}
						gLabel={"ORDERS IN BUSINESS EXCEPTIONS"}
					/>
				</Card>
				<Card className="eds-card eds-elevation--2">
					<GridItem
						gValue={
							<ul className="d-flex justify-content-space-between p-0 m-0">
								<li className="d-flex justify-content-space-between">
									<h2>
										{overview.avgProcessingTime &&
											`${Math.round(
												overview.avgProcessingTime.count / 1000
											)} S`}
									</h2>
								</li>
							</ul>
						}
						gLabel={"AVG. PROCESSING TIME/ORDER"}
					/>
				</Card>
				<Card
					className="eds-card eds-elevation--2"
					onClick={() => costRef.current.scrollIntoView()}
				>
					<GridItem
						gValue={
							<ul className="d-flex justify-content-space-between p-0 m-0 flex-direction-column">
								<li className="d-flex justify-content-space-between">
									<h2 className="m-0">
										${prodInfraUse.chartData ? prodInfraUse.chartData.cost : 0}
									</h2>
								</li>
								<li className="d-flex justify-content-space-between">
									<ul className="d-flex m-0 p-0 flex-direction-column">
										<li>
											{overview.received && (
												<p className="eds-type--legal-1 m-0">
													<span className="eds-type--legal-3">
														{getRenderedPercentage(prodInfraUse.lastDay)}
													</span>{" "}
													from yesterday
												</p>
											)}
										</li>
										<li>
											{overview.received && (
												<p className="eds-type--legal-1">
													<span className="eds-type--legal-3">
														{getRenderedPercentage(prodInfraUse.lastWeek)}
													</span>{" "}
													from last week
												</p>
											)}
										</li>
									</ul>
								</li>
							</ul>
						}
						gLabel={"TOTAL INFRA COST"}
					/>
				</Card>

				<Card
					className="eds-card eds-elevation--2"
					onClick={() => costRef.current.scrollIntoView()}
				>
					<GridItem
						gValue={
							<ul className="d-flex justify-content-space-between p-0 m-0">
								<li className="d-flex justify-content-space-between">
									<h2>
										{prodInfraUse.chartData ? prodInfraUse.chartData.count : 0}
									</h2>
								</li>
							</ul>
						}
						gLabel={"SERVICE COUNT"}
					/>
				</Card>
			</div>
			<div className="eds-grid eds-grid--m-cols-1 eds-grid--l-cols-1 mb-16">
				<Card className="eds-card eds-elevation--3 eds-grid--l-col-2 p-16 pb-0">
					<HourlyTrend
						fromDateString={fetchDateParams(date).fromDateString}
						toDateString={fetchDateParams(date).toDateString}
					/>
				</Card>
			</div>
			<div className="eds-grid eds-grid--m-cols-1 eds-grid--l-cols-3 mb-16">
				<Card className="eds-elevation--3 p-16 pb-0">
					<PieChartWithLabel
						title={"Orders By Sales Org"}
						chartId="order-by-po-type-graph"
						orderData={regionsCount}
						orient="vertical"
						internalRadius="0%"
						center={["50%", "50%"]}
						label={{
							alignTo: "edge",
							formatter: "{name|{b}}\n{time|{c} | {d}%}",
							minMargin: 5,
							edgeDistance: 10,
							lineHeight: 15,
							rich: {
								time: {
									fontSize: 14,
									color: "#555",
								},
							},
						}}
						formatter={"{a} <br/>{b} {d}% <b>{c}</b>"}
					/>
				</Card>

				<Card className="eds-card eds-elevation--3 p-16 pb-0">
					<OrderByTypeGraph
						title={"Orders By Order Types"}
						chartId={"top-order-intake-chan9nels"}
						orderData={orderByType}
						orient="vertical"
						internalRadius="0%"
						center={["32%", "50%"]}
						legendFormatter={`{b} ({d}%)`}
						formatter={"{a} <br/>{b} {d}% <b>{c}</b>"}
					/>
				</Card>
				<Card className="eds-card eds-elevation--3 p-16 pb-0">
					<SimpleEchart
						type="bar"
						direction="horizontal"
						hideTabs={1}
						title={"Orders By Intake Channels (Top 5)"}
						chartId={"top-order-intake-channels"}
						orderData={orderByIntakeChannel}
					/>
				</Card>
			</div>

			<div
				className="eds-grid eds-grid--m-cols-1 eds-grid--l-cols-1 mb-16"
				ref={costRef}
			>
				<Card className="eds-elevation--3 p-16 pb-0">
					<div className="eds-grid eds-grid--m-cols-1 eds-grid--l-cols-2 mb-16">
						<CostAndMemoryUtilization
							prodInfraUse={prodInfraUse}
							overview={overview}
							filterDate={date}
						/>
					</div>
				</Card>
			</div>
		</div>
	);
};

export default DailyTrendDashboard;
