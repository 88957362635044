import React, { useState, useEffect } from "react";
import { TextField } from "@nike/eds";
import { useDispatch, useSelector } from "react-redux";
import { setFilterDate } from "../../../../../_reducer/dashboardSlice";

const TopComponent = (props) => {
    
    const dt = new Date();
    // const [dateValue, setDateValue] = useState(dt);
    const dateValue = useSelector((state) => {
		const today = new Date();
        today.setDate(today.getDate() - 1);
		return state.dashboardSliceReducer.dashboardDate ? new Date(state.dashboardSliceReducer.dashboardDate) : today});
    
    const dispatch = useDispatch();
    // useEffect(() => {},[]);
    return (
        <div className="d-flex justify-content-space-between mb-16">
            <h2 className="text-center mb-16">
                OSD DAILY TRENDS AND METRICS REPORT
            </h2>
            <TextField
                id="id"
                type="Date" // default type
                hasErrors={false}
                value={`${dateValue.getFullYear()}-${String(dateValue.getMonth() + 1).padStart(2,'0')}-${String(dateValue.getDate()).padStart(2,'0')}`}
                onChange={(e) => {
                    const val = String(e.target.value).split('-');
                    const dt = new Date(`${parseInt(val[1])}/${val[2]}/${val[0]}`);
                    dispatch(setFilterDate(dt));
                    props.fetchData(dt);
                } }
                placeholder="Select Date"
                className="m-0"
            />
        </div>
    )
}

export default TopComponent;