import { Card, Tab, TabGroup, ToggleButton, ToggleButtonGroup } from "@nike/eds";
import React, { useState, useEffect } from "react";
import { fetchDateParams, getRenderedPercentage } from "../../../../../observer/dailyTrend";
import SimpleTable from "../../../dashboard/components/simpleTable/simpleTable";
import InfraUtilization from "../infraUtilization";
import SignalFx from "../signalFx/SignalFx";

const CostAndMemoryUtilization = (props) => {
	const [prodInfraUse, setProdInfraUse] = useState({});
	const [filterDate, setFilterDate] = useState("");
	const [activeProdTab, setActiveProdTab] = useState("prod-chart");
	// const [activeNonProdTab, setActiveNonProdTab] = useState("non-prod-chart");
	const [overview, setOverview] = useState({});
	const [activeMemroyChart, setActiveMemroyChart] = useState({
        sto: true,
        zcon: false,
        zdig: false,
        zsta: false,
    });

    useEffect(() => {
        setTimeout(() => {
            setProdInfraUse(props.prodInfraUse);
            setOverview(props.overview);
            setFilterDate(props.filterDate);
        }, 100);
    });
	return (
		<>
			<Card className="eds-card eds-elevation--1 eds-grid--l-col-1 p-16">
				<h3 className="grid-component-title eds-grid-1-m-cols-2 eds-grid--l-col-2">Production</h3>
				<TabGroup
					className="p-0"
					name="daily-cost-prod-group"
					activeId={activeProdTab}
					onChange={(e) => setActiveProdTab(e.target.id)}
				>
					<Tab id="prod-chart" className="eds-type--legal-1">Daily Infra Utilization</Tab>
					<Tab id="prod-table" className="eds-type--legal-1">Cost and Usage Breakdown</Tab>
				</TabGroup>
				<div hidden={activeProdTab == "prod-chart" ? false : true}>
					<div className="d-flex justify-content-space-between">
						<div>
							<p className="m-0 eds-type--body-3">Total Cost</p>
							<ul className="d-flex p-0 mb-16 justify-content-space-between">
								<li>
									<h5>
										${prodInfraUse.chartData ? prodInfraUse.chartData.cost : 0}{" "}
										&nbsp;
									</h5>
								</li>
								<li>
									{overview.received && (
										<p className="eds-type--legal-1 m-0">
											<span className="eds-type--legal-3">
												{getRenderedPercentage(prodInfraUse.lastDay)}
											</span>{" "}
											from yesterday &nbsp;
										</p>
									)}
								</li>
								<li>
									{overview.received && (
										<p className="eds-type--legal-1 m-0">
											<span className="eds-type--legal-3">
												{getRenderedPercentage(prodInfraUse.lastWeek)}
											</span>{" "}
											from last week
										</p>
									)}
								</li>
							</ul>
						</div>
						<div>
							<p className="m-0 eds-type--body-3">Service Count</p>
							<h5 className="mb-16">
								{prodInfraUse.chartData ? prodInfraUse.chartData.count : 0}
							</h5>
						</div>
					</div>

					<InfraUtilization
						date={filterDate}
						chartId={"daily-infra-utilization-prod"}
						orderData={prodInfraUse.chartData ? prodInfraUse.chartData : {}}
						hidden={!(activeProdTab.toString() === "prod-chart")}
					/>
				</div>
				<div hidden={activeProdTab == "prod-table" ? false : true}>
					<SimpleTable
						// title={"Cost and Usage Breakdown"}
						tableData={prodInfraUse.tableData ? prodInfraUse.tableData : {}}
					/>
				</div>
			</Card>
			<Card className="eds-card eds-elevation--1 eds-grid--l-col-1 p-16">
				<h3 className="grid-component-title eds-grid-1-m-cols-2 eds-grid--l-col-2">Non Production</h3>
				<div className="h-75 d-flex justify-content-center align-items-center">
					<h6>Under Development</h6>
				</div>
				{/* <TabGroup
					className="p-0"
					name="daily-cost-prod-group"
					activeId={activeNonProdTab}
					onChange={(e) => setActiveNonProdTab(e.target.id)}
				>
					<Tab id="non-prod-chart" className="eds-type--legal-1">Daily Infra Utilization</Tab>
					<Tab id="non-prod-table" className="eds-type--legal-1">Cost and Usage Breakdown</Tab>
				</TabGroup>
				<div hidden={activeNonProdTab == "non-prod-chart" ? false : true}>
					<div className="d-flex justify-content-space-between">
						<div>
							<p className="m-0 eds-type--body-3">Total Cost</p>
							<ul className="d-flex p-0 mb-16 justify-content-space-between">
								<li>
									<h5>
										${prodInfraUse.chartData ? prodInfraUse.chartData.cost : 0}{" "}
										&nbsp;
									</h5>
								</li>
								<li>
									{overview.received && (
										<p className="eds-type--legal-1 m-0">
											<span className="eds-type--legal-3">
												{getRenderedPercentage(prodInfraUse.lastDay)}
											</span>{" "}
											from yesterday &nbsp;
										</p>
									)}
								</li>
								<li>
									{overview.received && (
										<p className="eds-type--legal-1 m-0">
											<span className="eds-type--legal-3">
												{getRenderedPercentage(prodInfraUse.lastWeek)}
											</span>{" "}
											from last week
										</p>
									)}
								</li>
							</ul>
						</div>
						<div>
							<p className="m-0 eds-type--body-3">Service Count</p>
							<h5 className="mb-16">
								{prodInfraUse.chartData ? prodInfraUse.chartData.count : 0}
							</h5>
						</div>
					</div>

					<InfraUtilization
						date={filterDate}
						chartId={"daily-infra-utilization-non-prod"}
						orderData={prodInfraUse.chartData ? prodInfraUse.chartData : {}}
						hidden={!(activeProdTab.toString() === "non-prod-chart")}
					/>
				</div>
				<div hidden={activeNonProdTab == "non-prod-table" ? false : true}>
					<SimpleTable
						// title={"Cost and Usage Breakdown"}
						tableData={prodInfraUse.tableData ? prodInfraUse.tableData : {}}
					/>
				</div> */}
			</Card>
			<Card className="eds-elevation--1 eds-grid--l-col-2 p-16">
				<div className="eds-grid eds-grid--l-cols-1 eds-grid-1-m-cols-1 grid-component-header">
					<h3 className="grid-component-title m-0">
						CPU/Memory Utilization of OFOA Pipelines (Top 4)
					</h3>

                    <ToggleButtonGroup size="small" className="mb-16">
                        <ToggleButton
                            onClick={() => setActiveMemroyChart({...activeMemroyChart, sto: !activeMemroyChart.sto})}
                            active={activeMemroyChart.sto}
                            title="STO"
                            className="eds-type--body-3 br-0"
                        >
                            STO
                        </ToggleButton>
                        <ToggleButton
                            onClick={() => setActiveMemroyChart({...activeMemroyChart, zcon: !activeMemroyChart.zcon})}
                            active={activeMemroyChart.zcon}
                            title="ZCON"
                            className="eds-type--body-3 br-0"
                        >
                            ZCON
                        </ToggleButton>
                        <ToggleButton
                            onClick={() => setActiveMemroyChart({...activeMemroyChart, zdig: !activeMemroyChart.zdig})}
                            active={activeMemroyChart.zdig}
                            title="ZDIG"
                            className="eds-type--body-3 br-0"
                        >
                            ZDIG
                        </ToggleButton>
                        <ToggleButton
                            onClick={() => setActiveMemroyChart({...activeMemroyChart, zsta: !activeMemroyChart.zsta})}
                            active={activeMemroyChart.zsta}
                            title="ZSTA"
                            className="eds-type--body-3 br-0"
                        >
                            ZSTA
                        </ToggleButton>
                    </ToggleButtonGroup>

				</div>
				<div className="eds-grid eds-grid--m-cols-1 eds-grid--l-cols-2 mb-16">
					<Card className="eds-elevation--1 p-16" hidden={!activeMemroyChart.sto}>
						{activeMemroyChart.sto ?
							<SignalFx
								date={filterDate}
								chartId="signal-fx-1"
								type="STOCKORDER_CPU"
								title="Stock Transport Order - CPU %"
								colorGradientTop="#fccca8"
								colorGradientBottom="#ffaf73"
								colorGradientLine="#ff933e"
							/> : <></>
						}
					</Card>
					<Card className="eds-elevation--1 p-16" hidden={!activeMemroyChart.sto}>
						{activeMemroyChart.sto ?
							<SignalFx
								date={filterDate}
								chartId="signal-fx-2"
								type="STOCKORDER_MEMORY"
								title="Stock Transport Order - Memory %"
								colorGradientTop="#fccca8"
								colorGradientBottom="#ffaf73"
								colorGradientLine="#ff933e"
							/> : <></>
						}
					</Card>
					<Card className="eds-elevation--1 p-16" hidden={!activeMemroyChart.zcon}>
						{activeMemroyChart.zcon ?
							<SignalFx
								date={filterDate}
								chartId="signal-fx-3"
								type="CONTRACTORDER_CPU"
								title="Contract Orders - CPU %"
								colorGradientTop="#a9d6ec"
								colorGradientBottom="#65c0ed"
								colorGradientLine="#4cbdf5"
							/> : <></>
						}
					</Card>
					<Card className="eds-elevation--1 p-16" hidden={!activeMemroyChart.zcon}>
						{activeMemroyChart.zcon ?
							<SignalFx
								date={filterDate}
								chartId="signal-fx-4"
								type="CONTRACTORDER_MEMORY"
								title="Contract Orders - Memory %"
								colorGradientTop="#a9d6ec"
								colorGradientBottom="#65c0ed"
								colorGradientLine="#4cbdf5"
							/> : <></>
						}
					</Card>
					<Card className="eds-elevation--1 p-16" hidden={!activeMemroyChart.zdig}>
						{activeMemroyChart.zdig ?
							<SignalFx
								date={filterDate}
								chartId="signal-fx-5"
								title="Digital Orders - CPU %"
								type="DIGITALORDER_CPU"
								colorGradientTop="#faa8d1"
								colorGradientBottom="#f370b1"
								colorGradientLine="#ed5ea5"
							/> : <></>
						}
					</Card>
					<Card className="eds-elevation--1 p-16" hidden={!activeMemroyChart.zdig}>
						{activeMemroyChart.zdig ?
							<SignalFx
								date={filterDate}
								chartId="signal-fx-6"
								type="DIGITALORDER_MEMORY"
								title="Digital Orders - Memory %"
								colorGradientTop="#faa8d1"
								colorGradientBottom="#f370b1"
								colorGradientLine="#ed5ea5"
							/> : <></>
						}
					</Card>
					<Card className="eds-elevation--1 p-16" hidden={!activeMemroyChart.zsta}>
						{activeMemroyChart.zsta ?
							<SignalFx
								date={filterDate}
								chartId="signal-fx-7"
								type="STANDARDORDER_CPU"
								title="Standard Orders - CPU %"
								colorGradientTop="#b2ebab"
								colorGradientBottom="#85e779"
								colorGradientLine="#63ef51"
							/> : <></>
						}
					</Card>
					<Card className="eds-elevation--1 p-16" hidden={!activeMemroyChart.zsta}>
						{activeMemroyChart.zsta ?
							<SignalFx
								date={filterDate}
								chartId="signal-fx-8"
								type="STANDARDORDER_MEMORY"
								title="Standard Orders - Memory %"
								colorGradientTop="#b2ebab"
								colorGradientBottom="#85e779"
								colorGradientLine="#63ef51"
							/> : <></>
						}
					</Card>
				</div>
			</Card>
		</>
	);
};

export default CostAndMemoryUtilization;
