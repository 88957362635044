import React, { lazy, useEffect , useState} from "react";
import ToolbarSlot from './components/toolbarSlot/ToolbarSlot';
import { 
  Icon,
  Menu,
  MenuItem,
  Selectmport,
  VerticalNavbar,
  setActiveValueInNavItems,
  AppHeader,
  Tab,
  TabGroup,
  Button,
} from "@nike/eds";
import { CaretDown, CaretUp, NikeApp } from "@nike/nike-design-system-icons";

const Header = (props) => {
   
  return (
    <div>
      <AppHeader
        isDark
        logoSlot={<NikeApp color={"white"} width={"56px"} height={"64px"} />}
        appName="OSD Production Dashboard"
        toolbarSlot={<ToolbarSlot />}
      />
    </div>
  );
};

export default Header;
