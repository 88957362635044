import * as echarts from 'echarts';
import { useState, useEffect } from 'react';
import { Icon, Tooltip } from '@nike/eds';
import "./index.css";

var simpleChartOption;

const SimpleEchart = (props) => {

    // const [activeTab, setActiveTab] = useState(`${props.chartId}-daily-health`);
    // const [orderStatus, setOrderStatus] = useState('received');
    const [toolTip, setToolTip] = useState(false);
    const [hideTabs, setHideTabs] = useState(false);

    useEffect(() => {
        setToolTip(props.toolTip ? true : false);
        setHideTabs(props.hideTabs ? props.hideTabs : false);
        const xAxisData = [];
        const seariesData = [];
        if (props.orderData && props.orderData.data) {
            for (let i = 0; i < props.orderData.data.length; i++) {
                xAxisData.push(props.orderData.data[i].date);
                seariesData.push(props.orderData.data[i].count);
            }
        }
        simpleChartOption = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            [(props.direction && props.direction === 'horizontal') ? 'yAxis' : 'xAxis']: {
                type: 'category',
                data: xAxisData,
                axisTick: {
                    alignWithLabel: true
                }
            },
            [(props.direction && props.direction === 'horizontal') ? 'xAxis' : 'yAxis']: {
                type: 'value'
            },
            series: [
                {
                    data: seariesData,
                    type: props.type,
                    color: props.orderData.color,
                    fontSize: '9',
                }
            ]
        };


        var lineChartDom = document.getElementById(props.chartId);
        var lineChart = echarts.init(lineChartDom);
        simpleChartOption && lineChart.setOption(simpleChartOption);
    });

    return (
        <>
            <div className="eds-grid eds-grid--l-cols-3 eds-grid-1-m-cols-3 d-flex align-items-center justify-content-space-between">
                {props.title && (<h3 className="grid-component-title eds-grid-1-m-cols-2 eds-grid--l-col-2">{props.title} <span hidden={hideTabs}>({props.orderData.subTitle})</span>
                {toolTip && <Tooltip
                    bodySlot={(<div className="tooltip-container">{props.toolTip}</div>)}
                    placement={"bottom"}
                    enableFocus
                    isDark
                    >
                        <Icon
                        name="QuestionCircle"
                        size="s"
                        enableFocus
                        />
                    </Tooltip>}
                </h3>)
                }
                {/* <TabGroup
                    name="tab-button-group"
                    activeId={activeTab}
                    onChange={e => { setActiveTab(e.target.id); setOrderStatus(e.target.attributes.ordertype.value); props.fetchOrderData(props.orderData.type, props.orderData.page, e.target.attributes.ordertype.value) }}
                    className="eds-grid--l-cols-1 eds-grid-1-m-cols-1"
                    hidden={hideTabs}
                >
                    <Tab id={`${props.chartId}-daily-health`} ordertype="received" className="grid-component-title p-0">Received</Tab>
                    <Tab id={`${props.chartId}-split-orders`} ordertype="submitted" className="grid-component-title p-0">Submitted</Tab>
                </TabGroup> */}
            </div>

            <div className="com-chart-container">
                <Icon
                    className="arrow-left"
                    name="CaretLeft"
                    size="m"
                    onClick={() => props.fetchOrderData(props.orderData.type, (props.orderData.page + 1))}
                    enableFocus
                    hidden={hideTabs}
                />
                <div className="com-chart" id={props.chartId}></div>
                <Icon
                    className="arrow-right"
                    name="CaretRight"
                    size="m"
                    enableFocus
                    onClick={() => props.fetchOrderData(props.orderData.type, (props.orderData.page - 1))}
                    hidden={hideTabs || props.orderData.page === 0 ? true : false}
                />
            </div>
            {/* <p className="eds-type--legal-1 mt-16" style={{ color: 'red' }}>{props.orderData.highlights}</p> */}
        </>
    )
}

export default SimpleEchart;