import * as echarts from "echarts";
import { useEffect, useState } from "react";
import "@nike/eds/dist/index.css";
import { Icon, Tooltip } from "@nike/eds";

var myChart;
var option;
const OrderByTypeGraph = (props) => {
  const [toolTip, setToolTip] = useState(false);
  // const [data, setData] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setToolTip(props.toolTip ? true : false)
      myChart = echarts.init(document.getElementById(props.chartId));
      option = {
        tooltip: {
          trigger: 'item',
          formatter: props.formatter
        },
        legend: {
          type: 'scroll',
          orient: props.orient,
          bottom: 0,
          right: 0,
          textStyle: {
            fontSize: '10',
          }
        },
        series: [
          {
            name: props.title,
            type: 'pie',
            radius: [props.internalRadius, '95%'],
            center: props.center,
            avoidLabelOverlap: true,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '14',
                // fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: props.orderData.seriesData,
          }
        ]
      };

      option && myChart.setOption(option);
    }, 500);


  }, [props.orderData.seriesData]);

  return (
    <>
      <div className="eds-grid eds-grid--l-cols-1 eds-grid-1-m-cols-1 grid-component-header">
        <h3 className="grid-component-title mb-16">{props.title} 
          {toolTip && <Tooltip
          bodySlot={(<div className="tooltip-container">{props.toolTip}</div>)}
          placement={"bottom"}
          enableFocus
          isDark
        >
            <Icon
              name="QuestionCircle"
              size="s"
              enableFocus
            />
          </Tooltip>}
        </h3>
      </div>
      <div id={props.chartId} className="com-chart"></div>
    </>
  );
};

export default OrderByTypeGraph;
