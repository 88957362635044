import * as echarts from "echarts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@nike/eds/dist/index.css";
import { months } from "../../../../constants/dashboardConstants";
import { Icon } from "@nike/eds";

var myChart;
var option;

const labelOption = {
	show: true,
	position: 'insideBottom',
	distance: '-20',
	align: 'right',
	verticalAlign: 'bottom',
	rotate: '20',
	formatter: "{name|{a}}",
	// formatter: "{val|{c}}  {name|{a}}",
	fontSize: 16,
	rich: {
        val: {
            fontSize: 16,
            color: 'black'
        },
		name: {
            color: 'black'
        },
	},
};

const InfraUtilization = (props) => {
	const dispatch = useDispatch();
	const [mount, setMount] = useState(true);
	var orderDates = [];

	useEffect(() => {
		const legendOrderTypes = [];
		const date = new Date(props.date);
		const dateString = `${months[date.getMonth()]} - ${String(date.getDate()).padStart(
			2,
			"0"
		)}`;
		if (props.orderData.data && props.orderData.data.length > 0) {
			orderDates[dateString] = [];
			for (let i = 0; i < props.orderData.data.length; i++) {
				const dataByType = props.orderData.data[i];
				if (legendOrderTypes.indexOf(dataByType.name) === -1) {
					legendOrderTypes.push(dataByType.name);
				}
				orderDates[dateString][dataByType.name] = dataByType.value;
			}
		}

		myChart = echarts.init(document.getElementById(props.chartId));

		const legendDates = [dateString];

		option = {
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "shadow",
				},
			},
			legend: {
				data: legendOrderTypes,
				textStyle: {
					fontSize: "9",
				},
			},
			toolbox: {
				show: true,
				orient: "vertical",
				left: "right",
				top: "top",
				feature: {
					mark: { show: true },
					dataView: { show: true, readOnly: false },
					magicType: { show: true, type: ["line", "bar", "stack"] },
					restore: { show: true },
					saveAsImage: { show: true },
				},
			},
			grid: {
				top: "25%",
				bottom: "4%",
				left: "3%",
				right: "6%",
				containLabel: true,
			},
			xAxis: [
				{
                    show: true,
					name: "Services",
					// type: "category",
					// axisTick: { show: false },
					data: legendDates && legendDates.map((i) => i),
				},
			],
			yAxis: [
				{
					type: "value",
					name: "Cost",
				},
			],
			series: legendOrderTypes.map((orderType) => {
				return {
					name: orderType,
					type: "bar",
					barGap: 0.2,
                    // label: labelOption,
					emphasis: {
						focus: "series",
					},
					data:
						legendDates &&
						legendDates.map((date) =>
							orderDates[date] && orderDates[date][orderType]
								? orderDates[date][orderType]
								: 0
						),
				};
			}),
		};

		option && myChart.setOption(option);
	});

	return (
		<>
			<div className="eds-grid eds-grid--l-cols-1 eds-grid-1-m-cols-1 grid-component-header">
				{props.title && (
					<h3 className="grid-component-title mb-16">{props.title}</h3>
				)}
			</div>
			<div className="com-chart-container">
                <Icon
                    className="arrow-left"
                    name="CaretLeft"
                    size="m"
                    onClick={() => props.fetchInfraCostData(-1)}
                    enableFocus
                    hidden={props.showArrows ? false : true}
                />
				<div id={props.chartId} style={{ width: "100%", height: "350px" }}></div>
                <Icon
                    className="arrow-right"
                    name="CaretRight"
                    size="m"
                    enableFocus
                    onClick={() => props.fetchInfraCostData(+1)}
                    hidden={props.page < 0 && props.showArrows ? false : true}
                />
            </div>
		</>
	);
};

export default InfraUtilization;
