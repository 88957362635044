import * as echarts from "echarts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabGroup, Tab } from "@nike/eds";
import "@nike/eds/dist/index.css";
import { getOrderHourlyTrend } from "../../../../observer/dailyTrend";
// import { useForm } from "react-hook-form";

var app = {};
var myChart;
var option;
const posList = [
	"left",
	"right",
	"top",
	"bottom",
	"inside",
	"insideTop",
	"insideLeft",
	"insideRight",
	"insideBottom",
	"insideTopLeft",
	"insideTopRight",
	"insideBottomLeft",
	"insideBottomRight",
];
// app.configParameters = {
// 	rotate: {
// 		min: -90,
// 		max: 90,
// 	},
// 	align: {
// 		options: {
// 			left: "left",
// 			center: "center",
// 			right: "right",
// 		},
// 	},
// 	verticalAlign: {
// 		options: {
// 			top: "top",
// 			middle: "middle",
// 			bottom: "bottom",
// 		},
// 	},
// 	position: {
// 		options: posList.reduce(function (map, pos) {
// 			map[pos] = pos;
// 			return map;
// 		}, {}),
// 	},
// 	distance: {
// 		min: 0,
// 		max: 100,
// 	},
// };

const HourlyTrend = (props) => {
	const dispatch = useDispatch();
	const [mount, setMount] = useState(true);
	const [activeTab, setActiveTab] = useState("daily-health");
	var orderTypes = [];
	var orderDates = [];

	useEffect(() => {
		getOrderHourlyTrend({fromDateString: props.fromDateString, toDateString: props.toDateString}).then((dailyHealthData) => {
			const legendOrderTypes = [];

			if (dailyHealthData.data) {
				for (let i = 0; i < dailyHealthData.data.length; i++) {
					const hourlyData = dailyHealthData.data[i];
					Object.keys(hourlyData).forEach(function (v, i) {
						orderDates[v] = [];
						const orderTypeValues = hourlyData[v];
						if (orderTypeValues) {
							Object.keys(orderTypeValues).forEach(function (sv, i) {
								if (legendOrderTypes.indexOf(sv) === -1) {
									legendOrderTypes.push(sv);
								}
								orderDates[v][sv] = orderTypeValues[sv];
							});
						}
					});
				}
			}

			myChart = echarts.init(document.getElementById("order-graph"));

			app.config = {
				// rotate: 90,
				// align: "left",
				// verticalAlign: "middle",
				// position: "insideBottom",
				// distance: 0,
				// onChange: function () {
					// const labelOption = {
					// 	rotate: app.config.rotate,
					// 	align: app.config.align,
					// 	verticalAlign: app.config.verticalAlign,
					// 	position: app.config.position,
					// 	distance: app.config.distance,
					// };
					// myChart.setOption({
					// 	series: [
					// 		{
					// 			label: labelOption,
					// 		},
					// 		{
					// 			label: labelOption,
					// 		},
					// 		{
					// 			label: labelOption,
					// 		},
					// 		{
					// 			label: labelOption,
					// 		},
					// 	],
					// });
				// },
			};

			const legendDates = [];
            for (let i = 0; i < 24; i++) {
                legendDates.push(i);
			}

			// const labelOption = {
			// 	show: true,
			// 	position: app.config.position,
			// 	distance: app.config.distance,
			// 	align: app.config.align,
			// 	verticalAlign: app.config.verticalAlign,
			// 	rotate: app.config.rotate,
			// 	formatter: "",
			// 	fontSize: 16,
			// 	rich: {
			// 		name: {},
			// 	},
			// };
			option = {
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "shadow",
					},
				},
				legend: {
					data: legendOrderTypes,
					textStyle: {
						fontSize: "9",
					},
				},
				toolbox: {
					show: true,
					orient: "vertical",
					left: "right",
					top: "top",
					feature: {
						mark: { show: true },
						dataView: { show: true, readOnly: false },
						magicType: { show: true, type: ["line", "bar", "stack"] },
						restore: { show: true },
						saveAsImage: { show: true },
					},
				},
				grid: {
					top: '4%',
					bottom: '4%',
					left: '4%',
					right: '4%',
					containLabel: true
				},
				xAxis: [
					{
						name: "Hours",
						type: "category",
						axisTick: { show: false },
						data: legendDates && legendDates.map((i) => String(i).padStart(2, '0') + ":00"),
					},
				],
				yAxis: [
					{
						type: "value",
						name: "Orders (Count)",
					},
				],
				series: legendOrderTypes.map((orderType) => {
					return {
						name: orderType,
						type: "bar",
						barGap: 1,
						// label: labelOption,
						emphasis: {
							focus: "series",
						},
						data:
							legendDates &&
							legendDates.map((date) =>
								orderDates[date] && orderDates[date][orderType]
									? orderDates[date][orderType]
									: 0
							),
					};
				}),
			};

			option && myChart.setOption(option);
		});
	}, [props.fromDateString]);

	return (
		<>
			<div className="eds-grid eds-grid--l-cols-1 eds-grid-1-m-cols-1 grid-component-header">
				{/* <TabGroup
          name="tab-button-group"
          activeId={activeTab}
          onChange={e => setActiveTab(e.target.id)}
          className="mb-16"
        >
          <Tab id="daily-health" className="grid-component-title p-0">OFOA Daily Trends</Tab>
          <Tab id="split-orders" className="grid-component-title p-0">Split Orders</Tab>
        </TabGroup> */}
				<h3 className="grid-component-title mb-16">
					Hourly Trend Of Order Types
					{/* toolTip && <Tooltip
          bodySlot={(<div className="tooltip-container">{props.toolTip}</div>)}
          placement={"bottom"}
          enableFocus
          isDark
        >
            <Icon
              name="QuestionCircle"
              size="s"
              enableFocus
            />
          </Tooltip> */}
				</h3>
			</div>
			<div id="order-graph" style={{ height: '350px' }}></div>
		</>
	);
};

export default HourlyTrend;
