import { apiSource } from "../constants/dashboardConstants";
import { getSyncCall } from "../utils/apiAdapter";
// import { months } from "../constants/dashboardConstants";
import { Icon } from "@nike/eds";

// let today = new Date();
// let yesterday = new Date(today);
// yesterday.setDate(yesterday.getDate() - 1);

// const fromDateString = `${yesterday.getFullYear()}-${String(
// 	yesterday.getMonth() + 1
// ).padStart(2, "0")}-${String(yesterday.getDate()).padStart(2, "0")} 08:00:00`;
// const toDateString = `${today.getFullYear()}-${String(
// 	today.getMonth() + 1
// ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")} 08:00:00`;

// export const getDailyTrendExceptions = async () => {

//     const response = await getSyncCall(`${apiSource}/com-dashboard/v1/failedOrders/${fromDateString}/${toDateString}`, null, null)
//     return {
//         businessCases: response.businessCases,
//         technicalIssues: response.technicalIssues
//     };
// }

export const getDailyTnrendOverview = async (dates) => {
	const response = await getSyncCall(
		`${apiSource}/com-dashboard/v1/trend/orderStatus/${dates.fromDateString}/${dates.toDateString}`,
		null,
		null
	);

	return {
		avgProcessingTime: {
			count: response[0].currentData.count,
			trend: response[0].trend.up,
			value: response[0].trend.value,
		},
		received: {
			count: response[1].currentData.count,
			trend: response[1].trend.up,
			value: response[1].trend.value,
		},
		failedInS4: {
			count: response[2].currentData.count,
			trend: response[2].trend.up,
			value: response[2].trend.value,
		},
		businessCases: {
			count: response[3].currentData.count,
			trend: response[3].trend.up,
			value: response[3].trend.value,
		},
		technicalIssues: {
			count: response[4].currentData.count,
			trend: response[4].trend.up,
			value: response[4].trend.value,
		},
		splitOrders: {
			count: response[5].currentData.count,
			trend: response[5].trend.up,
			value: response[5].trend.value,
		},
	};
};

// export const getRegionsCount = async () => {
// 	const response = await getSyncCall(
// 		`${apiSource}/com-dashboard/v1/orders/regions`,
// 		null,
// 		null
// 	);
// 	return response;
// };

export const getOrderByIntakeChannel = async (dates) => {
	const response = await getSyncCall(
		`${apiSource}/com-dashboard/v1/trend/topOrderIntakeChannels/${dates.fromDateString}/${dates.toDateString}`,
		null,
		null
	);

	const data = [];

	for (let i = response.length - 1; i >= 0; i--) {
		data.push({ date: response[i].channel, count: response[i].count });
	}

	return {
		data: data,
		color: "#5470c6",
	};
};

export const getOrderByType = async (dates) => {
	const response = await getSyncCall(
		`${apiSource}/com-dashboard/v1/orders/daily/orderType?startDate=${dates.fromDateString}&endDate=${dates.toDateString}`,
		null,
		null
	);

	const data = [];
	Object.keys(response).forEach(function (v, i) {
		data.push({ name: v, value: response[v] });
	});

	return { seriesData: data };
};

export const getComDashboardItemDetails = async () => {
	const response = await getSyncCall(
		`${apiSource}/com-dashboard/v1/orders/item-details`,
		null,
		null
	);
	const data = [];
	for (var i = 0; i < response.length; i++) {
		data.push({
			0: response[i].orderType,
			1: Math.round(parseFloat(response[i].avgLine) * 100) / 100,
			2: response[i].totalLines,
			3: response[i].orderCount,
		});
	}

	return {
		header: ["Order Types", "Avg Lines", "Total Lines", "Total Orders"],
		body: data,
	};
};

export const getCaseCount = async (dates) => {
	const response = await getSyncCall(
		`${apiSource}/com-dashboard/v1/orders/daily/case-count?startDate=${dates.fromDateString}&endDate=${dates.toDateString}`,
		null,
		null
	);

	const data = [];
	Object.keys(response).forEach(function (v, i) {
		data.push({
			0: v,
			1: response[v],
		});
	});

	return {
		header: ["Case type", "Count"],
		body: data,
	};
};

export const getRegionsCount = async (dates) => {
	const response = await getSyncCall(
		`${apiSource}/com-dashboard/v1/orders/daily/regions?startDate=${dates.fromDateString}&endDate=${dates.toDateString}`,
		null,
		null
	);

	const seriesData = [];
	if (response) {
		const chinaCount =
			parseInt(response["9800"] ? response["9800"] : 0) +
			parseInt(response["NA"] ? response["NA"] : 0) +
			parseInt(response["XP00"] ? response["XP00"] : 0) +
			parseInt(response["null"] ? response["null"] : 0);
		const hkCount =
			parseInt(response["9600"] ? response["9600"] : 0) +
			parseInt(response["0"] ? response["0"] : 0);
		const taiwanCount = parseInt(response["9700"]);

		seriesData.push({
			name: "Greater China",
			value: chinaCount,
		});
		seriesData.push({
			name: "Hong Kong",
			value: hkCount,
		});
		seriesData.push({
			name: "Taiwan",
			value: taiwanCount,
		});
	}
	return {
		seriesData: seriesData,
	};
};

export const getOpenCasesByOrderTypes = async (dates) => {
	const response = await getSyncCall(
		`${apiSource}/com-dashboard/v1/orders/daily/case-count/orderType?startDate=${dates.fromDateString}&endDate=${dates.toDateString}`,
		null,
		null
	);

	const seriesData = [];

	Object.keys(response).forEach(function (v, i) {
		seriesData.push({
			name: v,
			value: response[v],
		});
	});
	return {
		seriesData: seriesData,
	};
};

export const getOrderHourlyTrend = async (dates) => {
	const response = await getSyncCall(
		`${apiSource}/com-dashboard/v1/orders/trend/hourly?startDate=${dates.fromDateString}&endDate=${dates.toDateString}`,
		null,
		null
	);
	return response;
};

export const getRenderedPercentage = (percent) => {
	if (parseFloat(percent) > 0 && percent !== "N/A") {
		return (percent = (
			<>
				<Icon name="ArrowUp" size="s" enableFocus color="green" />
				<span className="positive">{percent.replace("-", "") + "%"}</span>
			</>
		));
	} else if (parseFloat(percent) < 0 && percent !== "N/A") {
		return (percent = (
			<>
				<Icon name="ArrowDown" size="s" enableFocus color="red" />
				<span className="negative">{percent.replace("-", "") + "%"}</span>
			</>
		));
	}
};

export const getPercentileProcessTiming = async () => {
	const response = await getSyncCall(
		`${apiSource}/com-dashboard/v1/orders/percentile`,
		null,
		null
	);
	const rawTableData = [];

	if (response && response.OFOAProcessingTime) {
		let sumOpt10 = 0;
		let sumOpt50 = 0;
		let sumOpt90 = 0;
		for (let i in response.OFOAProcessingTime[90]) {
			sumOpt10 += response.OFOAProcessingTime[10][i]
				? Math.round(response.OFOAProcessingTime[10][i])
				: 0;
			sumOpt50 += response.OFOAProcessingTime[50][i]
				? Math.round(response.OFOAProcessingTime[50][i])
				: 0;
			sumOpt90 += response.OFOAProcessingTime[90][i]
				? Math.round(response.OFOAProcessingTime[90][i])
				: 0;
			rawTableData.push({
				label: i,
				opt10: response.OFOAProcessingTime[10][i]
					? Math.round(response.OFOAProcessingTime[10][i])
					: 0,
				opt50: response.OFOAProcessingTime[50][i]
					? Math.round(response.OFOAProcessingTime[50][i])
					: 0,
				opt90: response.OFOAProcessingTime[90][i]
					? Math.round(response.OFOAProcessingTime[90][i])
					: 0,
			});
		}
		rawTableData.push({
			label: "Total",
			opt10: sumOpt10,
			opt50: sumOpt50,
			opt90: sumOpt90,
		});
	}
	return rawTableData;
};

export const getDailyInfraUtilization = async (date, region) => {
	const yesterday = new Date(date);
	const today = new Date(date);
	today.setDate(yesterday.getDate() + 1);
	const response = await getSyncCall(
		`${apiSource}/com-dashboard/v1/trend/awsCostDetails/${yesterday.getFullYear()}-${String(yesterday.getMonth() + 1).padStart(2, '0')}-${String(yesterday.getDate()).padStart(2, '0')}/${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}?region=${region}`,
		null,
		null
	);

	const responseOld = await getSyncCall(
		`${apiSource}/com-dashboard/v1/trend/awsCostDetails/totalCost/${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}?region=${region}`,
		null,
		null
	);

	const tableRow = [];
	const seriesData = [];
	if(response.serviceCosts){
		for(let key in response.serviceCosts) {
			if(Math.round(parseFloat(response.serviceCosts[key].NetUnblendedCost.amount) * 100) > 0) {
				tableRow.push({
					0: key,
					1: Math.round(parseFloat(response.serviceCosts[key].NetUnblendedCost.amount) * 100) / 100,
				});
			}
		}
		tableRow.sort((a, b) => {
			return b[1] - a[1];
		});
		let othersCount = 0;
		tableRow.forEach((row, index) => {
			if(index < 4) {
				seriesData.push({
					name: row[0],
					value: row[1],
				});
			} else {
				othersCount += row[1];
			}
		})
		seriesData.push({
			name: 'Others',
			value: Math.round(othersCount * 100)/100,
		});
	}

	const tableData = {
		header: ["Services", "Daily Infra Cost Breakdown"],
		body: tableRow,
	};;
	
	return {
		tableData: tableData,
		chartData:{
			cost: response.totalCost ? Math.round(parseFloat(response.totalCost.NetUnblendedCost) * 100)/100 : 0,
			count: tableRow.length,
			data : seriesData
		},
		lastDay: responseOld.lastDay.NetUnblendedCost,
		lastWeek: responseOld.lastWeek.NetUnblendedCost,
	};
};

export const getResourceUtilization = async (dates) => {
	const yesterday = new Date(dates.fromDateString).getTime();
	const today = new Date(dates.toDateString).getTime();
	const result= {
		stocktransportpipeline: []
	};
	const response = await getSyncCall(
		`${apiSource}/com-dashboard/v1/trend/signalfxDashboard/${yesterday}/${today}/300000`,
		null,
		null
	);

	if(response.stocktransportpipeline) {
		response.stocktransportpipeline.forEach((data) => {
			const dt = new Date(data[0]);
			result.stocktransportpipeline.push({name: `${String(dt.getHours()).padStart(2,'0')}:${String(dt.getMinutes()).padStart(2,'0')}`, value: data[1]})
		})
	}

	return result
}

export const getSignalFxData = async (params) => {
	
	const legends = [];
	const values = [];

	const response = await getSyncCall(
		`${apiSource}/com-dashboard/v1/trend/signalfxDashboard/${params.fromDate}/${params.toDate}/1800000/${params.type}`,
		null,
		null
	);

	if (response.coordinates) {
		response.coordinates.forEach((coord) => {
			const myDate = new Date(coord.epochTimeMs);
			const pstDate = myDate.toLocaleTimeString("en-US", {
				timeZone: "America/Los_Angeles"
			});

			legends.push(pstDate);
			// legends.push(`${String(pstDate.getHours()).padStart(2,'0')}:${String(pstDate.getMinutes()).padStart(2,'0')}`);
			values.push(coord.value);
			// values.push(Math.round(coord.value * 100)/100);
		})
	}

	return {legends: legends, values: values};
} 

export const fetchDateParams = (date) => {
	let yesterday = new Date(date);
	let today = new Date(yesterday);
	today.setDate(today.getDate() + 1);
	
	const fromDateString = `${yesterday.getFullYear()}-${String(
		yesterday.getMonth() + 1
	).padStart(2, "0")}-${String(yesterday.getDate()).padStart(2, "0")} 08:00:00`;
	const toDateString = `${today.getFullYear()}-${String(
		today.getMonth() + 1
	).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")} 08:00:00`;

	return {fromDateString: fromDateString, toDateString: toDateString};
}

