export const orderTypes = [
  "Select Order Type",
  "ZCON",
  "ZDIG",
  "ZFIN",
  "ZFRE",
  "ZRFR",
  "ZRST",
  "ZSTA",
  "ZSYS",
  "ZCSG",
  "ZTRD",
  "ZS03",
  "ZS04",
  "ZS05",
  "ZS06",
  "ZS09",
  "ZS10",
];

export const poTypes = [
  "Select PO Type",
  "API",
  "C3PO",
  "CLLB",
  "CONV",
  "DIGI",
  "ECS",
  "EDI",
  "EXTF",
  "GSM",
  "INTF",
  "JES",
  "MANL",
  "NBY",
  "NBYE",
  "NET",
  "NTSB",
  "SMT",
  "UII",
  "WRSO",
];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const salesOrgTypes = ["Select Sales Org", "9600", "9700", "9800"];

export const reportType = {
  IN_PROGRESS: "In Progress",
  SENT_EXCEPTION: "Exception",
  SENT_DLQ: "Exception",
  SUBMITTED_SCPI: "Submitted to SCPI",
};

export const pieChartReportType = {
  IN_PROGRESS: "InProgress",
  SENT_EXCEPTION: "Exception",
  SENT_DLQ: "Dlq",
  SUBMITTED_SCPI: "Submittedtoscpi",
  SPLIT: "Split",
  RECEIVED: "Received",
  ARCHIVED: "Archived",
  ACKNOWLEDGED: "Acknowledged",
  FAILED_IN_S4: "Failed In S4",
};

const getApiSource = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'PROD':
      return process.env.REACT_APP_PROD_BACKEND_URL;
    case 'DELTA':
      return process.env.REACT_APP_DELTA_BACKEND_URL;
    case 'SIERRA':
      return process.env.REACT_APP_SIERRA_BACKEND_URL;
    case 'TEST':
      return process.env.REACT_APP_TEST_BACKEND_URL;
    case 'DEV':
      return process.env.REACT_APP_DEV_BACKEND_URL;
    default:
      return process.env.REACT_APP_DEV_BACKEND_URL;
  }
}
// OPS/COM Dashboard API domain
export const apiSource = getApiSource();

// export const apiSource = 
//   "https://osd-ops-dashboard-dev.mp-ordermgmt-test.nikecloud.com"; // Dev

// export const apiSource =
//   "https://osd-ops-dashboard-prod.mp-ordermgmt-prod.nikecloud.com"; // Prod

// export const apiSource =
//   "https://osd-ops-dashboard-test.mp-ordermgmt-test.nikecloud.com" // Test

export const orderByTypeToolTipData = (<ul>
  <li><b>ZFIN: Financial Orders</b><br/>Financial Digital Orders fulfilled by Hong Kong for invoice reconciliation of orders</li>
  <li><b>ZCON: Contract Orders</b><br/>Wholesale Orders placed against existing Sales Order contracts for reserved inventory</li>
  <li><b>ZFRE: Nike Elite Pipeline</b><br/>Orders placed by Nike Contracted Athletes and their Guests</li>
  <li><b>ZRFR:</b><br/>Wholesale Free of Charge Return Orders with mandatory validations specific to Returns</li>
  <li><b>ZRST :</b><br/>Wholesale Standard Return Orders with mandatory validations specific to Returns</li>
  <li><b>ZSTA: Standard orders</b><br/>Billable orders that include both standalone and contract reference varieties</li>
  <li><b>STO: STock Transport Orders</b><br/>Purchase Orders transferred between a Distribution Center to a Digital Hub (DH) or Store or DH to Store</li>
  <li><b>RSTO: Return STO</b><br/>Transports from a DH to a DC or Store</li>
  <li><b>ZSYS: System Orders</b><br/>Dummy system sales order used to secure inventory from a contract onto an STO.</li>
</ul>)

export const regions = {
  GC: {title: 'MAGC', desc: 'Greater China'},
  NA: {title: 'MANA', desc: 'North America'},
  EMEA: {title: 'EMEA', desc: 'Europe, Middle East & Africa'}
};