import React, { useEffect } from "react";
import Routes, { getOktaAuthToken } from "../../../_routes/routes";
import "./Main.css";
import "../../../_styles/css_styles/dashboard.css";
import Header from '../header/Header';
import QualtricsSurvey from "../../../container/qualtricsSurvey";
import SnackbarLoader from "../../../container/snackbarLoader";

const Main = (props) => {
  return (
    <>
      <div className="mainContainer">
        <div className="appContainer">
          <Header />
          <Routes />
        </div>
        <QualtricsSurvey/>
        <SnackbarLoader/>
      </div>
    </>
  );
};

export default Main;
