import React, { useState, useEffect } from "react";
import * as echarts from "echarts";
import { getSignalFxData } from "../../../../../observer/dailyTrend";

let option;

const SignalFx = (props) => {

    useEffect(() => {
        setTimeout(() => {
            const today = new Date(props.date);
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            const todayUTC = new Date(Date.UTC(today.getFullYear(),today.getMonth(),today.getDate(),7,30,0)).getTime();
            const yesterdayUTC = new Date(Date.UTC(yesterday.getFullYear(),yesterday.getMonth(),yesterday.getDate(),8,0,0)).getTime();
            const myChart = echarts.init(document.getElementById(props.chartId));
            getSignalFxData({fromDate: yesterdayUTC, toDate: todayUTC, type: props.type}).then((data) => {
                option = {
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "shadow",
                        },
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: data.legends,
                        onZero: false
                    },
                    yAxis: {
                        type: "value",
                        scale: true
                    },
                    grid: {
                        left: 40,
                        bottom: 20,
                        right: 20,
                        top: 20
                    },
                    series: [
                        {
                            name: 'Avg. Utilization',
                            data: data.values,
                            type: "line",
                            emphasis: {
                                focus: "series",
                            },
                            itemStyle: {
                                color: props.colorGradientLine ? props.colorGradientLine : "rgb(255, 70, 131)",

                            },
                            areaStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: props.colorGradientTop ? props.colorGradientTop : "rgb(255, 158, 68)",
                                    },
                                    {
                                        offset: 1,
                                        color: props.colorGradientBottom ? props.colorGradientBottom : "rgb(255, 70, 131)",
                                    },
                                ]),
                            },
                        },
                    ],
                };
                option && myChart.setOption(option);
                myChart.resize();
            });
        },100);
	},[props.date]);
	return (
		<>
			<div className="eds-grid eds-grid--l-cols-3 eds-grid-1-m-cols-3 d-flex align-items-center justify-content-space-between">
				{props.title && (
					<h3 className="grid-component-title eds-grid-1-m-cols-2 eds-grid--l-col-2">
						{props.title}
					</h3>
				)}
			</div>

			<div className="com-chart-container">
				<div className="com-chart" style={{ width: '100%', height: '120px' }} id={props.chartId}></div>
			</div>
		</>
	);
};
export default SignalFx;
